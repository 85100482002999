@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

// Header
.header-camp {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100px;
  padding: 10px 0px;
  // position: sticky;

  @include media("<=tablet") {
    padding: 20px 0px;
  }
  .wrap {
    @include media("<=phoneH") {
      // flex-direction: column;
    }
    .left {
      flex: 1;
      .logo {
        height: 40px;
        img {
          height: 100%;
          width: 100%;
          // border-radius: 50%;
          // border: 2px solid #fff;
        }
      

        @media only screen and (max-width:340px) {
          img{
            height:100%;
            width:120%;
          }          
        }

        
      }

      
      @media only screen and (min-width: 340px) {
        .logo {
          height: 55px;
        }
    }


    }
    .right {
      flex: 0.5;
      justify-content: end;
      @media only screen and (min-width:992px) {
        .right{
          flex:1;
        }
        
      }
      .action {
        .btn {
          font-size: 18px;
          border-radius: 4px;
        }

        @media only screen and (min-width:340px) {
          .btn{
            font-size:14px;
            border-radius: 4px;
          }
          
        }
      }
    }
  }
}
