.heading{
    text-align: center;
    color:white;
    justify-content: center;
    padding-top:30px;
    font-size:20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  

    .best-looks{
      justify-content: center;
 
    }

    @media only screen and (min-width:992px){
      .best-looks{
        justify-content: center;
        margin-left:140px;
        margin-right:140px;

      }
    }

    .h1{
        padding-top:30px;
        font-size:large;
        margin-top:30px;

    }

    .top-heading{
      font-size:50px;
    }

    @media only screen and (max-width:420px) {
      .top-heading{
        font-size:30px;
      }
      
    }




    // .btn {
    //     color:black;
    //     background-color: yellow;
    //     width: 35%;
    //     font-size: 24px;
    //     font-weight: 600;
    //     text-transform: uppercase;
    //     border-radius: 4px;
    //     margin-top:30px;
    //     white-space:nowrap;
    //   }

    //   @media only screen and (min-width:992px) {
    //     .btn{
    //         width:15%;
    //     }
        
    //   }
}

.Image-stake{
  width: auto;
  height: auto;
}